import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-enterprise";
import { DashboardRowDataInterface } from "../../types/common";
import { useAuthStore } from "../../store";
import DownloadButton from "../../components/components";
import {
  useGetAllApprover,
  useGetAllReviewer,
  useGetAllTask,
} from "../../queries/onboarding";
import QuickSearch from "../../components/QuickSearch";
import { ApproverModel, DocumentType } from "../../types/onBoardingModel";
import OnboardingPanel from "../dashboard/OnboardingPanel";
import { AgNameComponent } from "../../components/AgComponent";
import { formatDisplayDate } from "../../utils/formatDateDisplay";
import { getApproverStatus, getReviewStatus } from "../../utils/Utility";
import UserProfile from "./Users";

enum Role {
  Intern = "Intern",
  Admin = "Admin",
  Legal = "Legal",
}

const UserRoleTable: React.FC = () => {
  const user = useAuthStore();
  const gridApi = useRef<any>(null);

  const { data: approver } =
    user.role === "Admin" || user.role === "SuperAdmin"
      ? useGetAllApprover()
      : { data: [] };
  const { data: reviewer } =
    user.role === "Admin" || user.role === "Legal" || user.role === "SuperAdmin"
      ? useGetAllReviewer()
      : { data: [] };
  const [listofUser, setListofUser] = useState<ApproverModel[]>([]);
  useEffect(() => {
    const userList: ApproverModel[] = [];
    approver?.forEach((ele) =>
      userList.push({
        id: ele.id,
        name: ele.name,
        email: ele.email,
        password: ele.password,
        role: ele.role,
        createdAt: ele.createdAt,
        updatedAt: ele.updatedAt,
      }),
    );
    reviewer?.forEach((ele) =>
      userList.push({
        id: ele.id,
        name: ele.name,
        email: ele.email,
        password: ele.password,
        role: ele.role,
        createdAt: ele.createdAt,
        updatedAt: ele.updatedAt,
      }),
    );
    setListofUser(userList);
  }, [approver, reviewer]);

  const [columnDefs] = useState<ColDef<ApproverModel>[]>([
    {
      field: "name",
      headerName: "Name",
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      field: "email",
      headerName: "email",
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      field: "role",
      headerName: "role",
      filter: "agSetColumnFilter",
      sortable: true,
    },
  ]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      flex: 1,
      wrapHeaderText: true,
      suppressColumnsToolPanel: true,
      menuTabs: ["filterMenuTab"],
      columnChooserParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      filter: true,
      resizable: true,
    }),
    [],
  );

  const [panelData, setPanelData] = useState();

  const [open, setOpen] = useState(false);

  return (
    <div className="h-[calc(100vh-10vh)]">
      <div className="flex flex-row justify-between px-8 pt-4">
        {panelData && open && (
          <OnboardingPanel
            reviewer={reviewer ?? []}
            approver={approver ?? []}
            value={panelData}
            open={open}
            onSuccess={() => {
              setOpen(false);
            }}
            close={() => {
              setOpen(false);
            }}
          />
        )}
        <div>
          <h1 className="mt-4 text-xl text-secondary font-bold">User List</h1>

          <p className="text-sm text-gray-500 font-medium pb-2">
            {listofUser?.length} User
          </p>
        </div>

        <div className="justify-end flex flex-row py-2 items-center">
          <QuickSearch
            placeholder={`Search`}
            className=""
            onChange={(e) => {
              gridApi.current.api.setGridOption(
                "quickFilterText",
                e.target.value,
              );
            }}
          />
        </div>
      </div>
      <div className="ag-theme-quartz px-8 pb-4 flex" style={{ height: "90%" }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={listofUser}
          onGridReady={(params) => {
            gridApi.current = params;
          }}
          alwaysMultiSort
          defaultColDef={defaultColDef}
          className="w-9/12 col"
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: "#f8f8f8" };
            } else {
              return { background: "#ffffff" };
            }
          }}
        />
        <UserProfile />
      </div>
    </div>
  );
};

export default UserRoleTable;
