import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";
import { Fragment } from "react";
import { Outlet, useMatch, useNavigate } from "react-router";
import Avatar from "../components/Avatar";
import { queryClient } from "../queries/client";
import { useAuthStore } from "../store";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function MainLayout() {
  const { clear } = useAuthStore();
  function logout() {
    localStorage.removeItem("accesstoken");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("companyId");
    localStorage.removeItem("hissaUrl");
    queryClient.clear();
    clear();
  }

  const user = useAuthStore();
  const userNavigation = [{ name: "Log out", onClick: logout }];
  const navigation =
    useAuthStore().role === "Admin" || useAuthStore().role === "SuperAdmin"
      ? [
          { name: "Captable Onboarding", href: "/captable", current: true },

          { name: "Users", href: "/users", current: true },
          { name: "Upload", href: "/upload", current: true },
          { name: "ESOP Stats", href: "/esopStats", current: true },
          { name: "Captable Stats", href: "/captableStats", current: true },
          { name: "Global Investor", href: "/globalInvestor", current: true },
          { name: "MCA Orders", href: "/mcaCreatedOrder", current: true },
        ]
      : useAuthStore().role === "Legal"
        ? [
            { name: "Captable Onboarding", href: "/captable", current: true },
            { name: "Upload", href: "/upload", current: true },
          ]
        : [{ name: "Captable Onboarding", href: "/captable", current: true }];
  const navigate = useNavigate();

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full">
        <Disclosure as="nav" className="border-b border-gray-200 bg-secondary">
          {({ open }) => (
            <>
              <div className="px-4 mx-auto sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                  <div className="flex">
                    <div className="flex items-center flex-shrink-0">
                      <img
                        className="w-auto h-5"
                        src="https://equion-dev.s3.us-west-2.amazonaws.com/logonew.svg"
                        alt="hissa_logo"
                      />
                    </div>
                    <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                      {navigation.map((item) => {
                        const match = useMatch(`/${item.href.split("/")[1]}/*`);
                        return (
                          <a
                            key={item.name}
                            onClick={() => {
                              navigate(item.href);
                            }}
                            className={classNames(
                              match
                                ? "border-b border-white text-white duration-300"
                                : "border-transparent text-gray-400 hover:border-gray-300 hover:text-gray-300",
                              "inline-flex items-center border-b-2 px-1 pt-1 text-base font-medium cursor-pointer",
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </a>
                        );
                      })}
                    </div>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:items-center">
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="relative flex items-center max-w-xs text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>

                          <Avatar name={user.userName ?? ""} />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  onClick={item.onClick}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700",
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <div className="flex items-center -mr-2 sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="relative inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <Icon
                          icon="octicon:x-16"
                          className="h-5 text-blue-700 cursor-pointer"
                          height={20}
                        />
                      ) : (
                        <Icon
                          icon="ion:menu"
                          className="h-5 text-blue-700 cursor-pointer"
                          height={20}
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="pt-2 pb-3 space-y-1">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "border-indigo-500 bg-indigo-50 text-indigo-700"
                          : "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800",
                        "block border-l-4 py-2 pl-3 pr-4 text-base font-medium",
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="pt-4 pb-3 border-t border-gray-200">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <Avatar name={user.userName ?? ""} />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">
                        {user.userName}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 space-y-1">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        onClick={item.onClick}
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <main className="mx-auto sm:px-6 lg:px-8 h-[calc(100vh-10vh)]">
          <Outlet />
        </main>
      </div>
    </>
  );
}
