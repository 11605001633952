import { QueryFunctionContext } from "react-query";
import {
  ApproverModel,
  CinValidation,
  CreateDownloadOrderType,
  GridTableModelData,
  ReviewerModel,
} from "../types/onBoardingModel";
import captableApi from "./captableApi";
import onBoardingApi from "./onBoardingApi";
import postOnBoardingApi from "./postOnBoarding";

import { GlobalCompanySearchType } from "../pages/onboarding/financialUpload/FinancialUploadPage";

export async function getAllTask(
  context: QueryFunctionContext,
): Promise<GridTableModelData[]> {
  return onBoardingApi.get(`stage3/allTasks`).then((res) => res.data.data);
}

export async function getAllApprover(
  context: QueryFunctionContext,
): Promise<ReviewerModel[]> {
  return onBoardingApi.get(`stage3/allApprovers`).then((res) => res.data.data);
}

export async function getAllReviewer(
  context: QueryFunctionContext,
): Promise<ApproverModel[]> {
  return onBoardingApi.get(`stage3/allReviewers`).then((res) => res.data.data);
}

export async function addCompanyTask(data: any): Promise<any> {
  return onBoardingApi
    .post(`stage3/addCompanyTask`, data)
    .then((res) => res.data);
}

export async function addApprover(data: any): Promise<any> {
  return onBoardingApi.post(`stage3/addApprover`, data).then((res) => res.data);
}

export async function addReviewer(data: any): Promise<any> {
  return onBoardingApi.post(`stage3/addReviewer`, data).then((res) => res.data);
}

export async function addTaskStatus(data: any): Promise<any> {
  return onBoardingApi
    .put(`stage3/onboardingStatus`, data)
    .then((res) => res.data);
}

export async function addFinalExcel({
  formData,
}: {
  formData: FormData;
}): Promise<any> {
  return postOnBoardingApi
    .post(`stage3/finalExcel`, formData)
    .then((res) => res.data);
}

export async function deleteFinalExcel(data: any): Promise<any> {
  return onBoardingApi
    .delete(`stage3/deleteFinalExcel?companyDocumentId=${data}`)
    .then((res) => res.data);
}

export async function uploadCompanyExcel(data: { file: any }): Promise<any> {
  return captableApi
    .post(`company/t3Onboarding?isPrivate=false`, { file: data.file })
    .then((res) => res.data.data);
}
export async function getGlobalCompaniesDetails(
  context: QueryFunctionContext,
): Promise<GlobalCompanySearchType[]> {
  return onBoardingApi
    .get(`captableCompanies/getGlobalCompaniesDetails`)
    .then((res) => res.data.data);
}

export async function createMCAOrder(
  cins: { cin: string; documentType: string }[],
): Promise<any> {
  return onBoardingApi
    .post(`stage3/create-order`, cins)
    .then((res) => res.data.data);
}
export async function cinValidation(
  formData: FormData,
): Promise<CinValidation[]> {
  return onBoardingApi
    .post(`stage3/cin-validate`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data.data);
}

export async function getCreatedMCAOrder(): Promise<any> {
  return onBoardingApi
    .get(`stage3/fetchCreatedOrder`)
    .then((res) => res.data.data);
}

export async function getAllCreatedMCAOrder(): Promise<
  CreateDownloadOrderType[]
> {
  return onBoardingApi
    .get(`stage3/fetchCreatedOrder`)
    .then((res) => res.data.data);
}

export async function checkOrderStatus(): Promise<any> {
  return onBoardingApi
    .post(`stage3/check-order-status`, {})
    .then((res) => res.data.data);
}
