import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef, CellClickedEvent } from "ag-grid-enterprise";
import { DashboardRowDataInterface } from "../../types/common";
import { useAuthStore } from "../../store";
import DownloadButton from "../../components/components";
import {
  useGetAllApprover,
  useGetAllReviewer,
  useGetAllTask,
} from "../../queries/onboarding";
import QuickSearch from "../../components/QuickSearch";
import { DocumentType } from "../../types/onBoardingModel";
import OnboardingPanel from "./OnboardingPanel";
import { AgNameComponent } from "../../components/AgComponent";
import { formatDisplayDate } from "../../utils/formatDateDisplay";
import { getApproverStatus, getReviewStatus } from "../../utils/Utility";
import { DateUtil } from "../../utils/dateUtils";
import { sideBar } from "../../utils/AgGridConfig";
import { PrimaryCTAButton } from "../../components/CTAButtonComponents";

enum Role {
  Intern = "Intern",
  Admin = "Admin",
  Legal = "Legal",
  SuperAdmin = "SuperAdmin",
}

const MyAgGridTable: React.FC = () => {
  const user = useAuthStore();
  const role = user.role;
  const gridApi = useRef<any>(null);
  const [searchResultCompanies, setSearchResultCompanies] = useState(0);

  const { data: approver } =
    user.role === "Admin" ||
    user.role === "SuperAdmin" ||
    user.role === "Intern"
      ? useGetAllApprover()
      : { data: [] };
  const { data: reviewer } =
    user.role === "Admin" || user.role === "Legal" || user.role === "SuperAdmin"
      ? useGetAllReviewer()
      : { data: [] };
  const { data: allTask, refetch } = useGetAllTask();

  useEffect(() => {
    if ((approver ?? []).length > 0 && (reviewer ?? []).length > 0) refetch();
  }, [approver, reviewer]);
  useEffect(() => {
    setSearchResultCompanies(
      gridApi?.current?.api?.getDisplayedRowCount() || 0,
    );
  }, [gridApi?.current?.api, allTask]);

  const componentsRegistery = useMemo(
    () => ({
      finalExcel: memo(DownloadButton),
    }),
    [],
  );

  const [columnDefs] = useState<ColDef<DashboardRowDataInterface>[]>([
    {
      field: "companyName",
      headerName: "Company Name",
      filter: "agTextColumnFilter",
      getQuickFilterText: (params) => params.value,
      cellRenderer: AgNameComponent,
      sortable: true,
    },
    {
      getQuickFilterText: (params) => params.data.cin,
      field: "finalExcel",
      headerName: "Final Excel",
      cellRenderer: "finalExcel",
      sortable: false,
      initialWidth: 150,
    },
    {
      field: "reviewer",
      headerName: "Reviewer",
      initialWidth: 150,
      hide: role === Role.Intern,
    },
    {
      field: "reviewerStatus",
      headerName: "Reviewer Status",
      initialWidth: 120,
    },
    {
      field: "approver",
      headerName: "Approver",
      hide: !(role === Role.Admin || role === Role.SuperAdmin),
      initialWidth: 150,
    },
    {
      field: "approverStatus",
      headerName: "Approver status",
      initialWidth: 120,
    },
    {
      field: "deadline",
      headerName: "Deadline",
      filter: "agDateColumnFilter",
      sortable: true,
      initialWidth: 120,
      valueFormatter: (params) =>
        DateUtil.formatDate(params.value, "DD MMM YYYY"),
      filterParams: {
        comparator: (dateFromFilter: Date, cellValue: any) => {
          if (cellValue == null) {
            return 0;
          }
          const dateParts = cellValue.split("-");
          const day = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[0]);
          const cellDate = new Date(year, month, day);
          if (cellDate < dateFromFilter) {
            return -1;
          } else if (cellDate > dateFromFilter) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      field: "lastModified",
      headerName: "Last modified",
      filter: "agDateColumnFilter",
      sortable: true,
      initialWidth: 120,
      filterParams: {
        comparator: (dateFromFilter: Date, cellValue: any) => {
          if (cellValue == null) {
            return 0;
          }
          const dateParts = cellValue.split("-");
          const day = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[0]);
          const cellDate = new Date(year, month, day);
          if (cellDate < dateFromFilter) {
            return -1;
          } else if (cellDate > dateFromFilter) {
            return 1;
          }
          return 0;
        },
      },
    },
    { field: "note", headerName: "Note", editable: true },
  ]);

  const rowData = useMemo(
    () =>
      allTask?.map((template) => ({
        companyName: template.name,
        documentUri: template.onboarding_uri,
        date: formatDisplayDate(template.createdAt ?? ""),
        taskId: template.taskCompany[0].taskId,
        cin: template.taskCompany[0].cin,
        latestMgt7:
          template.taskCompany[0].CompanyDocument.find(
            (ele) => ele.documentType === DocumentType.ListOfShareHolders,
          )?.documentUrl ?? "",
        latestMgt7Id:
          template.taskCompany[0].CompanyDocument.find(
            (ele) => ele.documentType === DocumentType.ListOfShareHolders,
          )?.id ?? "",
        pas3BeforeMgt7Date:
          template.taskCompany[0].CompanyDocument.find(
            (ele) => ele.documentType === DocumentType.Pas3,
          )?.documentUrl ?? "",
        pas3BeforeMgt7DateId:
          template.taskCompany[0].CompanyDocument.find(
            (ele) => ele.documentType === DocumentType.Pas3,
          )?.id ?? "",
        excelGeneratedBySystem:
          template.taskCompany[0].CompanyDocument.find(
            (ele) => ele.documentType === DocumentType.stage3Sheet,
          )?.documentUrl ?? "",
        excelGeneratedBySystemId:
          template.taskCompany[0].CompanyDocument.find(
            (ele) => ele.documentType === DocumentType.stage3Sheet,
          )?.id ?? "",
        finalExcel:
          template.taskCompany[0].CompanyDocument.find(
            (ele) => ele.documentType === DocumentType.finalStage3Sheet,
          )?.documentUrl ?? "",
        finalExcelId:
          template.taskCompany[0].CompanyDocument.find(
            (ele) => ele.documentType === DocumentType.finalStage3Sheet,
          )?.id ?? "",
        confidence:
          template.taskCompany[0].CompanyDocument.find(
            (ele) => ele.documentType === DocumentType.stage3Sheet,
          )?.confidence ?? "",
        reviewer:
          reviewer?.find((ele: any) => ele.id === template.assignedTo)?.name ??
          "",
        reviewerStatus: getReviewStatus(template.onboardingState),
        approver:
          (approver ?? []).length > 0
            ? approver?.find((ele: any) => ele.id === template.approverId)
                ?.name ?? ""
            : "",
        approverStatus: getApproverStatus(template.onboardingState),
        latestMgt7Date: template.taskCompany[0].CompanyDocument.find(
          (ele) => ele.documentType === DocumentType.ListOfShareHolders,
        )?.documentDate, //formatDisplayDate(template.updatedAt),
        pas3BeforeMgt7DateIdDate: template.taskCompany[0].CompanyDocument.find(
          (ele) => ele.documentType === DocumentType.Pas3,
        )?.documentDate, //formatDisplayDate(template.updatedAt),
        deadline: template.updatedAt,
        lastModified: formatDisplayDate(template.updatedAt ?? ""),
        note: template.note ?? "",
        publish: "",
      })),
    [allTask, approver, reviewer],
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      flex: 1,
      wrapHeaderText: true,
      suppressColumnsToolPanel: true,
      menuTabs: ["filterMenuTab"],
      columnChooserParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      filter: true,
      resizable: true,
    }),
    [],
  );

  const [panelData, setPanelData] = useState();
  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() === "companyName") {
      const template = cellParams.data;
      setPanelData(template);
      setOpen(true);
    }
  };
  const [open, setOpen] = useState(false);
  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key);
        } else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  return (
    <>
      {" "}
      <div className="flex flex-row justify-between">
        {panelData && open && (
          <OnboardingPanel
            reviewer={reviewer ?? []}
            approver={approver ?? []}
            value={panelData}
            open={open}
            onSuccess={() => {
              setOpen(false);
            }}
            close={() => {
              setOpen(false);
            }}
          />
        )}
        <div>
          <h1 className="mt-4 text-xl text-secondary font-bold">
            Hissa Onboarding
          </h1>

          <p className="text-sm text-gray-500 font-medium pb-2">
            {searchResultCompanies} companies
          </p>
        </div>

        <div className="justify-end flex flex-row py-2 items-center">
          <QuickSearch
            placeholder={`Search`}
            onChange={(e) => {
              gridApi.current.api.setGridOption(
                "quickFilterText",
                e.target.value,
              );
              setSearchResultCompanies(
                gridApi.current?.api.getDisplayedRowCount() || 0,
              );
            }}
          />
        </div>
      </div>
      <div className="flex justify-end gap-2 py-2">
        <PrimaryCTAButton
          onClick={() => {
            openToolPanel("columns");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="size-4 me-1"
          >
            <path d="M9.836 3h-3.67v10h3.67V3ZM11.336 13H13.5a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 13.5 3h-2.164v10ZM2.5 3h2.166v10H2.5A1.5 1.5 0 0 1 1 11.5v-7A1.5 1.5 0 0 1 2.5 3Z" />
          </svg>
          Columns
        </PrimaryCTAButton>
        <PrimaryCTAButton
          onClick={() => {
            openToolPanel("filters");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="size-4 me-1"
          >
            <path d="M14 2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v2.172a2 2 0 0 0 .586 1.414l2.828 2.828A2 2 0 0 1 6 9.828v4.363a.5.5 0 0 0 .724.447l2.17-1.085A2 2 0 0 0 10 11.763V9.829a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 0 14 4.172V2Z" />
          </svg>
          Filter
        </PrimaryCTAButton>
      </div>
      <div className="ag-theme-quartz" style={{ width: "100%", height: "90%" }}>
        <AgGridReact
          components={componentsRegistery}
          columnDefs={columnDefs}
          rowData={rowData}
          rowClass={"py-1"}
          onCellClicked={handleCellClick}
          onGridReady={(params) => {
            gridApi.current = params;
          }}
          alwaysMultiSort
          defaultColDef={defaultColDef}
          sideBar={sideBar}
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: "#f8f8f8" };
            } else {
              return { background: "#ffffff" };
            }
          }}
        />
      </div>
    </>
  );
};

export default MyAgGridTable;
