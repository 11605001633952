import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { differenceInDays } from "date-fns";
import QuickSearch from "../../../components/QuickSearch";
import { useGetEsopCompanyStats } from "../../../queries/companyStats/esopStats";
import { CompanyDetailPage, StatusRenderer } from "./EsopAgComponents";
import { PrimaryCTAButton } from "../../../components/CTAButtonComponents";
import { sideBar } from "../../../utils/AgGridConfig";
import { formatDisplayDate } from "../../../utils/formatDateDisplay";

export type EsopDetails = {
  id: string;
  companyId: string;
  companyName: string;
  dbaName: string;
  onPlatformSince: Date;
  cin: string;
  companyPoolSize: number;
  totalActivePlanPoolSize: number;
  totalNumberOfPlans: number;
  totalNumberOfEmployees: number;
  totalNumberOfResignedEmployees: number;
  numberOfEmployeesGivenAccess: number;
  totalCashedout: number;
  totalLapsed: number;
  totalStockExercise: number;
  totalSurrendered: number;
  totalVested: number;
  totalGranted: number;
  totalSold: number;
  onboardingStatus: string;
  companyStatus: string;
  adminUsers: number;
};

function EsopStats() {
  const gridApi = useRef<AgGridReact>(null);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [searchResultCompanies, setSearchResultCompanies] = useState(0);
  const { data, refetch } = useGetEsopCompanyStats();
  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const navigate = useNavigate();
  // const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
  // if (darkThemeMq.matches) {
  //   // Theme set to dark.
  // } else {
  //   // Theme set to light.
  // }
  // window
  //   .matchMedia("(prefers-color-scheme: dark)")
  //   .addEventListener("change", (e) => {
  //     setIsDarkTheme(e.matches);
  //     console.log(e);
  //   });

  useEffect(() => {
    setSearchResultCompanies(
      gridApi?.current?.api?.getDisplayedRowCount() || 0,
    );
  }, [gridApi?.current?.api]);
  const components = useMemo(
    () => ({
      companyName: CompanyDetailPage,
      onboarding_status: StatusRenderer,
    }),
    [],
  );
  const rowsData = useMemo(() => {
    const compData = data?.sort((a: any, b: any) =>
      a.companyName.localeCompare(b.companyName),
    );
    let finalData: any[] = [];
    const newComp: any[] = [];
    compData?.forEach((i: EsopDetails) => {
      const days = differenceInDays(new Date(), new Date(i.onPlatformSince));
      if (days <= 30) {
        newComp.push({
          ...i,
          companyId: i.companyId,
          companyName: i.companyName,
          dbaName: i.dbaName,
          onPlatformSince: formatDisplayDate(i.onPlatformSince),
          cin: i.cin,
          companyPoolSize: Intl.NumberFormat("en-IN").format(i.companyPoolSize),
          totalActivePlanPoolSize: Intl.NumberFormat("en-IN").format(
            i.totalActivePlanPoolSize,
          ),
          totalNumberOfPlans: i.totalNumberOfPlans,
          totalNumberOfEmployees: i.totalNumberOfEmployees,
          numberOfEmployeesGivenAccess: i.numberOfEmployeesGivenAccess,
          totalNumberOfResignedEmployees: i.totalNumberOfResignedEmployees,
          totalCashedout: Intl.NumberFormat("en-IN").format(i.totalCashedout),
          totalLapsed: Intl.NumberFormat("en-IN").format(i.totalLapsed),
          totalStockExercise: Intl.NumberFormat("en-IN").format(
            i.totalStockExercise,
          ),
          totalSurrendered: Intl.NumberFormat("en-IN").format(
            i.totalSurrendered,
          ),
          totalVested: Intl.NumberFormat("en-IN").format(i.totalVested),
          totalGranted: Intl.NumberFormat("en-IN").format(i.totalGranted),
          totalSold: Intl.NumberFormat("en-IN").format(i.totalSold),
          onboarding_status: i.onboardingStatus
            .replaceAll("_", " ")
            .toLowerCase(),
          company_status: i.companyStatus.replaceAll("_", " ").toLowerCase(),
        });
      } else {
        finalData.push({
          ...i,
          companyId: i.companyId,
          companyName: i.companyName,
          dbaName: i.dbaName,
          onPlatformSince: formatDisplayDate(i.onPlatformSince),
          cin: i.cin,
          companyPoolSize: Intl.NumberFormat("en-IN").format(i.companyPoolSize),
          totalActivePlanPoolSize: Intl.NumberFormat("en-IN").format(
            i.totalActivePlanPoolSize,
          ),
          totalNumberOfPlans: i.totalNumberOfPlans,
          totalNumberOfEmployees: i.totalNumberOfEmployees,
          numberOfEmployeesGivenAccess: i.numberOfEmployeesGivenAccess,
          totalNumberOfResignedEmployees: i.totalNumberOfResignedEmployees,
          totalCashedout: Intl.NumberFormat("en-IN").format(i.totalCashedout),
          totalLapsed: Intl.NumberFormat("en-IN").format(i.totalLapsed),
          totalStockExercise: Intl.NumberFormat("en-IN").format(
            i.totalStockExercise,
          ),
          totalSurrendered: Intl.NumberFormat("en-IN").format(
            i.totalSurrendered,
          ),
          totalVested: Intl.NumberFormat("en-IN").format(i.totalVested),
          totalGranted: Intl.NumberFormat("en-IN").format(i.totalGranted),
          totalSold: Intl.NumberFormat("en-IN").format(i.totalSold),
          onboarding_status: i.onboardingStatus
            .replaceAll("_", " ")
            .toLowerCase(),
          company_status: i.companyStatus.replaceAll("_", " ").toLowerCase(),
        });
      }
    });
    finalData = [...newComp, ...finalData];
    return finalData;
  }, [data]);
  const columnDefs: ColDef[] = [
    {
      headerName: "Company Name",
      field: "companyName",
      cellRenderer: "companyName",
      filter: "agTextColumnFilter",
      // sort: "asc",
    },
    {
      headerName: "DBA Name",
      field: "dbaName",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Onboarding Status",
      field: "onboarding_status",
      cellRenderer: "onboarding_status",
      minWidth: 200,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Company Status",
      field: "company_status",
      filter: "agTextColumnFilter",
      cellClass: "capitalize",
    },
    {
      headerName: "Admin Users",
      field: "adminUsers",
    },
    {
      headerName: "Company Pool Size",
      field: "companyPoolSize",
      cellStyle: { display: "flex", justifyContent: "center" },
    },
    {
      headerName: "Total Active Plan Pool Size",
      field: "totalActivePlanPoolSize",
      cellStyle: {
        display: "flex",
        justifyContent: "center",
      },
    },
    {
      headerName: "Total Number of Plans",
      field: "totalNumberOfPlans",
      cellStyle: { display: "flex ", justifyContent: "center" },
    },
    {
      headerName: "Total Number of Employees",
      field: "totalNumberOfEmployees",
      cellStyle: { display: "flex ", justifyContent: "center" },
    },
  ];
  const defaultColDef = useMemo<ColDef>(
    () => ({
      resizable: true,
      filter: true,
      minWidth: 150,
      flex: 1,
    }),
    [],
  );
  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key);
        } else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };
  return (
    <>
      <div className="flex justify-between py-5">
        <div>
          <h1 className="text-2xl font-medium">Esop Companies</h1>
          <p className="pb-2 text-sm font-medium text-gray-500">
            {searchResultCompanies} companies
          </p>
        </div>
        <div>
          <QuickSearch
            placeholder="Search"
            onChange={(e) => {
              gridApi.current!.api.setGridOption(
                "quickFilterText",
                e.target.value,
              );
              setSearchResultCompanies(
                gridApi.current?.api.getDisplayedRowCount() || 0,
              );
            }}
          />
        </div>
      </div>
      <div
        className={`${isDarkTheme ? "ag-theme-quartz-dark" : "ag-theme-quartz"} h-[calc(100vh-20vh)]`}
      >
        <div className="py-2 flex justify-end gap-2">
          <PrimaryCTAButton
            onClick={() => {
              openToolPanel("columns");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="size-4 me-1"
            >
              <path d="M9.836 3h-3.67v10h3.67V3ZM11.336 13H13.5a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 13.5 3h-2.164v10ZM2.5 3h2.166v10H2.5A1.5 1.5 0 0 1 1 11.5v-7A1.5 1.5 0 0 1 2.5 3Z" />
            </svg>
            Columns
          </PrimaryCTAButton>
          <PrimaryCTAButton
            onClick={() => {
              openToolPanel("filters");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="size-4 me-1"
            >
              <path d="M14 2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v2.172a2 2 0 0 0 .586 1.414l2.828 2.828A2 2 0 0 1 6 9.828v4.363a.5.5 0 0 0 .724.447l2.17-1.085A2 2 0 0 0 10 11.763V9.829a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 0 14 4.172V2Z" />
            </svg>
            Filter
          </PrimaryCTAButton>
        </div>
        <AgGridReact
          alwaysMultiSort
          ref={gridApi}
          components={components}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={rowsData}
          suppressRowTransform={true}
          pagination={true}
          animateRows={true}
          // sideBar={agConfigSideBar}
          tooltipInteraction={true}
          sideBar={sideBar}
          tooltipShowDelay={50}
          rowStyle={{ cursor: "pointer" }}
          onRowClicked={(e) => {
            navigate("/companyDetails", { state: e.data });
          }}
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: "#f8f8f8" };
            } else {
              return { background: "#ffffff" };
            }
          }}
        />
      </div>
    </>
  );
}

export default EsopStats;
