/* eslint-disable import/no-extraneous-dependencies */
import { Icon } from "@iconify/react";
import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { PrimaryCTAButton } from "../../components/CTAButtonComponents";
import { Select } from "../../components/inputComponent";
import { HStack } from "../../components/utils";
import { useCinValidation, useCreateMCAOrder } from "../../queries/onboarding";
import { CinValidation } from "../../types/onBoardingModel";
import ValidationErrorsDialog from "../onboarding/s3Upload/Validation";

const CreateOrder: React.FC = () => {
  const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const { mutate: uploadCompanyCIN } = useCreateMCAOrder();
  const { mutate: cinValidation } = useCinValidation();
  const [excelUploadedData, setExcelUploadedData] = useState<CinValidation[]>(
    [],
  );

  const [documentType, setDocumentType] = useState<CinValidation>({
    cin: "",
    isDuplicate: "false",
    documentType: "",
  });

  const [showTable, setShowTable] = useState<boolean>(false);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const allowedFileExtensions = [".xlsm", ".xlsx"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      const formData = new FormData();
      formData.append("file", file);
      cinValidation(formData, {
        onSuccess: (data) => {
          setExcelUploadedData(data);
          setShowTable(true);
        },
        onError: (error: any) => {
          toast(error.response.data.errorMessage, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    } else {
      toast(
        ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", ",
        )}`,
        { type: "error" },
      );
    }
    fileRef.current.value = "";
  }

  function createOrder() {
    const orderRequestForCIN = excelUploadedData.map((data) => ({
      cin: data.cin,
      documentType: data.documentType as string,
    }));
    uploadCompanyCIN(orderRequestForCIN, {
      onSuccess: (data) => {
        if (data === "credits not available...") {
          toast(data, {
            autoClose: 2000,
            type: "warning",
          });
        } else {
          setShowTable(false);
          toast(data, {
            autoClose: 2000,
            type: "success",
          });
        }
      },
      onError: (error: any) => {
        toast(error.response.data.errorMessage, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  }

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: string[];
  }>({
    open: false,
  });

  const gridApi = useRef<AgGridReact>(null);
  const rowsData = useMemo(() => {
    return excelUploadedData?.map((mcaorder) => ({
      cin: mcaorder.cin,
      isDuplicate: mcaorder.isDuplicate.toString(),
      documentType: mcaorder.documentType,
    }));
  }, [excelUploadedData]);
  const ButtonCellRenderer = (props: any) => {
    function deleteRecord() {
      const remainingData = excelUploadedData.filter(
        (b) => b.cin !== props.data.cin,
      );
      setExcelUploadedData(remainingData);
    }
    return (
      <div className="">
        <Icon
          icon={"mingcute:delete-line"}
          onClick={() => deleteRecord()}
          height={24}
          className="cursor-pointer hover:text-orange-501"
        />
      </div>
    );
  };
  const DocumentTypeCellRenderer = (props: any) => {
    function selectDocumentType(type: string) {
      setExcelUploadedData((prevState) =>
        prevState.map((state) => {
          if (props.data.cin === state.cin) {
            return { ...state, documentType: type };
          }
          return state;
        }),
      );
    }
    return (
      <Select
        options={["Both", "V2", "V3"]}
        value={props.data.documentType}
        onChange={(e) => selectDocumentType(e.target.value)}
        className="h-8 max-w-28"
      />
    );
  };
  const columnDefs: ColDef[] = [
    {
      headerName: "Company CIN",
      field: "cin",
      cellRenderer: "cin",
    },
    {
      headerName: "Document Type",
      field: "documentType",
      cellRenderer: DocumentTypeCellRenderer,
    },
    {
      headerName: "Order In Pending",
      field: "isDuplicate",
      cellClass: "max-w-28",
    },
    {
      field: "delete",
      headerName: "Delete CIN",
      cellRenderer: ButtonCellRenderer,
      cellClass: "flex align-middle flex-col",
      filter: false,
      sortable: false,
      suppressHeaderMenuButton: true,
    },
  ];

  const defaultColDef = useMemo<ColDef>(
    () => ({
      resizable: true,
      filter: true,
      minWidth: 100,
      flex: 1,
    }),
    [],
  );
  return (
    <>
      <main className="grid gap-6 bg-white rounded-lg place-items-center">
        {!showTable ? (
          <div className="flex flex-col w-full h-full py-24 text-center ">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Hissa
            </h1>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Please upload the excel sheet to create order
            </p>
            <div className="flex items-center justify-center mt-10 gap-x-6">
              <div>
                <input
                  ref={fileRef}
                  onChange={handleChange}
                  multiple={false}
                  type="file"
                  accept=".xlsx, .xlsm"
                  hidden
                />
                <PrimaryCTAButton
                  onClick={() => {
                    fileRef.current.click();
                  }}
                >
                  Upload Excel
                </PrimaryCTAButton>
              </div>
              <ValidationErrorsDialog
                errors={dialog.data || []}
                value={dialog.open}
                onSuccess={() => setDialog({ open: false })}
                close={() => setDialog({ open: false })}
              />
            </div>
          </div>
        ) : (
          <div className="w-[85%] py-5 ">
            <HStack className="flex items-center justify-between w-full py-5">
              <div className="flex flex-row gap-4"></div>
              <div className="flex flex-row justify-end gap-4 pr-6">
                <Icon
                  icon={"mingcute:close-fill"}
                  height={32}
                  className="cursor-pointer hover:text-orange-501"
                  onClick={() => setShowTable(false)}
                />
              </div>
            </HStack>
            <div className={`ag-theme-quartz h-[calc(100vh-35vh)] px-4`}>
              <AgGridReact
                ref={gridApi}
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                rowData={rowsData}
                suppressRowTransform={true}
                pagination={true}
                animateRows={true}
                getRowStyle={(params) => {
                  if (params.rowIndex % 2 === 0) {
                    return { background: "#f8f8f8" };
                  } else {
                    return { background: "#ffffff" };
                  }
                }}
              />
            </div>
            <HStack className="flex items-center justify-end w-full p-5">
              <div className="flex flex-row gap-4">
                <div
                  className="p-2 text-white rounded-sm cursor-pointer bg-orange-501 h-9"
                  onClick={() => createOrder()}
                >
                  Create Order
                </div>
              </div>
            </HStack>
          </div>
        )}
      </main>
    </>
  );
};

export default CreateOrder;
