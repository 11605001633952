import axios, { AxiosError, AxiosRequestHeaders } from "axios";
import { useAuthStore } from "../store";
import { clearCredentials, revalidateAuth } from "../components/utils";
import { getCaptableToken } from "../pages/onboarding/s3Upload/getToken";

const captableApi = axios.create({
  baseURL:
    process.env.REACT_APP_CAPTABLE_URL || "https://captable-api.hissa.com/v1/",
});

captableApi.defaults.headers.post["Content-Type"] = "application/json";

captableApi.interceptors.request.use(async (config) => {
  const accesstoken = (await getCaptableToken()) ?? "";
  revalidateAuth();
  if (accesstoken && !config.url?.endsWith("/login")) {
    (config.headers as AxiosRequestHeaders).AccessToken = accesstoken;
  }
  if (config.url === "exit") {
    delete (config.headers as AxiosRequestHeaders).AccessToken;
  }
  return config;
});

captableApi.interceptors.response.use(
  (res) => {
    if (res.status === 401) {
      clearCredentials();
      useAuthStore.setState((store) => ({ ...store, isAuthenticated: false }));
      return Promise.reject(res);
    }
    return res;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      clearCredentials();
      useAuthStore.setState((store) => ({ ...store, isAuthenticated: false }));
    }
    return Promise.reject(error);
  },
);

export default captableApi;
