import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { ColDef, RowSelectionOptions } from "ag-grid-enterprise";
import { toast } from "react-toastify";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../../../components/CTAButtonComponents";
import QuickSearch from "../../../components/QuickSearch";
import {
  useDeleteCompanyRequest,
  useGetCompanyStats,
  useGetExportExcel,
} from "../../../queries/companyStats/captableStats";
import { downloadExcel } from "../../../utils/download";
import ViewUsersCellRenderer, { ViewDetailsCellRenderer } from "./cellRenderer";
import { formatDisplayDate } from "../../../utils/formatDateDisplay";
import { useAuthStore } from "../../../store/authStore";
import UserAccessPopup from "./userAccessPopup";
import CompanyDetailsPopup from "./CompanyDetailsPopup";
import { DeleteCompanyPopup, EditCompanyPopup } from "./Components";
import { sideBar } from "../../../utils/AgGridConfig";

type CaptableCompanyDetails = {
  id: string;
  cin: string;
  name: string;
  isPrivate: boolean;
  isClient: boolean;
  noOfShareholders: number;
  noOfRounds: number;
  onPlatformSince: Date;
  dba: string;
  noOfCaptables: number;
  companyAccessData: CompanyAccessData[];
  stage: string;
};
type CompanyAccessData = {
  fullName: string;
  email: string;
  createdAt: Date;
  role: string;
};
function CaptableStats() {
  const gridApi = useRef<AgGridReact>(null);
  const { mutate: exportToexcel } = useGetExportExcel();
  const {
    data: companyStats,
    refetch,
    isLoading,
    isFetched,
  } = useGetCompanyStats();
  const { mutate: deleteRequest } = useDeleteCompanyRequest();
  const [searchResultCompanies, setSearchResultCompanies] = useState(0);
  const [userList, setUserList] = useState();
  const [open, setOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isCompanyDetailsPopup, setIsCompanyDetailsPopup] = useState(false);
  const [disable, setDisable] = useState(true);
  const [editOpen, setEditOpen] = useState(false);
  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const user = useAuthStore();
  useEffect(() => {
    setSearchResultCompanies(
      gridApi?.current?.api?.getDisplayedRowCount() || 0,
    );
  }, [gridApi?.current?.api, companyStats]);
  useEffect(() => {
    refetch();
  }, []);
  const handleExcelDownload = () => {
    exportToexcel(companyStats, {
      onSuccess: (res) => {
        const fileName = `Company Details Report`;
        downloadExcel(res, fileName);
      },
      onError: () => {},
    });
  };
  const handleDeleteRequest = () => {
    const row: any = gridApi.current?.api.getSelectedRows();
    setOpen(true);
    deleteRequest(row, {
      onSuccess: (res: any) => {
        if (res !== undefined) {
          toast("OTP Generated Succesfully", {
            type: "success",
            autoClose: 2000,
          });
        }
      },
      onError: (error: any) => {
        toast(error.response.data.errors, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  };
  const defaultColDef = useMemo<ColDef>(
    () => ({
      resizable: true,
      filter: true,
      minWidth: 150,
      flex: 1,
    }),
    [],
  );
  const rowSelection = useMemo<RowSelectionOptions>(() => {
    return {
      mode: "singleRow",
    };
  }, []);
  const columnDefs: ColDef[] = [
    {
      headerName: "Name",
      field: "name",
      sortable: true,
    },
    {
      headerName: "On Platform Since",
      field: "onPlatformSince",
      sortable: true,
    },
    {
      headerName: "Private Company",
      field: "isPrivate",
      sortable: true,
    },
    {
      headerName: "Client Company",
      field: "isClient",
      sortable: true,
    },
    {
      headerName: "Stage",
      field: "stage",
      sortable: true,
    },
    {
      headerName: "View Users",
      field: "userEmails",
      cellRenderer: ViewUsersCellRenderer,
      onCellClicked: (e) => {
        setUserList(e.data);
        setIsPopupOpen(true);
      },
    },
    {
      headerName: "View Details",
      cellRenderer: ViewDetailsCellRenderer,
      onCellClicked: (e) => {
        setUserList(e.data);
        setIsCompanyDetailsPopup(true);
      },
    },
    {
      headerName: "Shareholders",
      field: "noOfShareholders",
      sortable: true,
    },
    {
      headerName: "Rounds",
      field: "noOfRounds",
      sortable: true,
    },

    {
      headerName: "Captables",
      field: "noOfCaptables",
      sortable: true,
    },

    {
      headerName: "CIN",
      field: "cin",
      sortable: true,
    },
  ];
  const rowsData = useMemo(
    () =>
      companyStats?.map((company: CaptableCompanyDetails) => ({
        name: company.name,
        onPlatformSince: formatDisplayDate(company.onPlatformSince),
        isPrivate: company.isPrivate ? "Yes" : "No",
        isClient: company.isClient ? "Yes" : "No",
        stage: company.stage,
        noOfShareholders: company.noOfShareholders,
        noOfRounds: company.noOfRounds,
        noOfCaptables: company.noOfCaptables,
        cin: company.cin,
        companyAccessData: company.companyAccessData,
        id: company.id,
      })),
    [isFetched],
  );
  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key);
        } else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };
  return (
    <>
      <div className="flex justify-between">
        <div>
          <h1 className="text-2xl font-medium">Captable Companies</h1>
          <p className="text-sm text-gray-500 font-medium pb-2">
            {searchResultCompanies} companies
          </p>
        </div>
        <div className="flex py-2">
          {user.role === "SuperAdmin" && (
            <>
              <SecondaryCTAButton
                onClick={() => {
                  setEditOpen(true);
                }}
                className={`me-2 ${disable ? "cursor-not-allowed" : "cursor-pointer"} my-1`}
                disabled={disable}
              >
                Edit
              </SecondaryCTAButton>
              <SecondaryCTAButton
                onClick={() => {
                  handleDeleteRequest();
                }}
                className={`me-2 ${disable ? "cursor-not-allowed" : "cursor-pointer"} my-1`}
                disabled={disable}
              >
                Delete
              </SecondaryCTAButton>
            </>
          )}
          <QuickSearch
            placeholder="Search"
            onChange={(e) => {
              gridApi.current!.api.setGridOption(
                "quickFilterText",
                e.target.value,
              );
              setSearchResultCompanies(
                gridApi.current?.api.getDisplayedRowCount() || 0,
              );
            }}
          />
        </div>
      </div>
      <div className="flex justify-end gap-2 py-2">
        <PrimaryCTAButton
          onClick={() => {
            handleExcelDownload();
          }}
          disabled={isLoading}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="size-4"
          >
            <path
              fillRule="evenodd"
              d="M15 11a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v6ZM7.25 7.5a.5.5 0 0 0-.5-.5H3a.5.5 0 0 0-.5.5V8a.5.5 0 0 0 .5.5h3.75a.5.5 0 0 0 .5-.5v-.5Zm1.5 3a.5.5 0 0 1 .5-.5H13a.5.5 0 0 1 .5.5v.5a.5.5 0 0 1-.5.5H9.25a.5.5 0 0 1-.5-.5v-.5ZM13.5 8v-.5A.5.5 0 0 0 13 7H9.25a.5.5 0 0 0-.5.5V8a.5.5 0 0 0 .5.5H13a.5.5 0 0 0 .5-.5Zm-6.75 3.5a.5.5 0 0 0 .5-.5v-.5a.5.5 0 0 0-.5-.5H3a.5.5 0 0 0-.5.5v.5a.5.5 0 0 0 .5.5h3.75Z"
              clipRule="evenodd"
            />
          </svg>
          Export To Excel
        </PrimaryCTAButton>
        <PrimaryCTAButton
          onClick={() => {
            openToolPanel("columns");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="size-4 me-1"
          >
            <path d="M9.836 3h-3.67v10h3.67V3ZM11.336 13H13.5a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 13.5 3h-2.164v10ZM2.5 3h2.166v10H2.5A1.5 1.5 0 0 1 1 11.5v-7A1.5 1.5 0 0 1 2.5 3Z" />
          </svg>
          Columns
        </PrimaryCTAButton>
        <PrimaryCTAButton
          onClick={() => {
            openToolPanel("filters");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="size-4 me-1"
          >
            <path d="M14 2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v2.172a2 2 0 0 0 .586 1.414l2.828 2.828A2 2 0 0 1 6 9.828v4.363a.5.5 0 0 0 .724.447l2.17-1.085A2 2 0 0 0 10 11.763V9.829a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 0 14 4.172V2Z" />
          </svg>
          Filter
        </PrimaryCTAButton>
      </div>
      <div className={`ag-theme-quartz h-[calc(100vh-28vh)]`}>
        <AgGridReact
          ref={gridApi}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={rowsData}
          suppressRowTransform={true}
          pagination={true}
          animateRows={true}
          rowSelection={rowSelection}
          sideBar={sideBar}
          onSelectionChanged={(e: any) => {
            const a =
              e.api.getSelectedRows().length === 0
                ? setDisable(true)
                : setDisable(false);
          }}
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: "#f8f8f8" };
            } else {
              return { background: "#ffffff" };
            }
          }}
        />
      </div>
      {isPopupOpen && (
        <UserAccessPopup
          isPopupOpen
          userList={userList}
          onClose={() => {
            setIsPopupOpen(false);
          }}
        />
      )}
      {isCompanyDetailsPopup && (
        <CompanyDetailsPopup
          isCompanyDetailsPopup
          userList={userList}
          onClose={() => {
            setIsCompanyDetailsPopup(false);
          }}
        />
      )}
      {editOpen && (
        <EditCompanyPopup
          showPopup={editOpen}
          gridRef={gridApi}
          onClose={() => {
            setEditOpen(false);
          }}
          refetchTableData={() => {
            refetch();
          }}
        />
      )}
      {open && (
        <DeleteCompanyPopup
          showPopup={open}
          onClose={() => {
            setOpen(false);
          }}
          gridRef={gridApi}
          refetchTableData={() => {
            refetch();
          }}
        />
      )}
    </>
  );
}

export default CaptableStats;
