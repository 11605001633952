import { useMutation, useQuery } from "react-query";
import {
  esopAdminDetails,
  esopCompanyDetails,
  esopDeleteUser,
  esopEditDetails,
  getEsopCompanyStats,
} from "../../api/esopStats";

export function useGetEsopCompanyStats() {
  return useQuery({
    queryKey: "getesopcompanystats",
    queryFn: getEsopCompanyStats,
  });
}
export function useAdminUserDetails() {
  return useMutation({
    mutationKey: "esopAdminDetails",
    mutationFn: esopAdminDetails,
  });
}
export function useCompanyDetails() {
  return useMutation({
    mutationKey: "esopCompanyDetails",
    mutationFn: esopCompanyDetails,
  });
}
export function useDeleteUser() {
  return useMutation({
    mutationKey: "esopDeleteUser",
    mutationFn: esopDeleteUser,
  });
}
export function useEditDetails() {
  return useMutation({
    mutationKey: "esopEditDetails",
    mutationFn: esopEditDetails,
  });
}
